<template>
  <div class="menu-wrap">
    <el-menu
      :default-active="$route.name"
      :router="true"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
    >
      <template
        v-for="(item, index) in menuData"
        :index="item.url"
        @click="handleMenu"
      >
        <div class="content" :key="index">
          <el-submenu v-if="item.children.length > 0" :index="item.url">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span slot="title">{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="item2 in item.children"
                :key="item2.id"
                :index="item2.url"
                @click="handleMenu"
              >
                <i :class="item2.icon"></i>
                <span slot="title">{{ item2.name }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item
            v-else
            :key="item.id"
            :index="item.url"
            @click="handleMenu"
          >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </div>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCollapse: false,
      menuData: [
        // data
        // {
        //   id: 1,
        //   name: "我的课程",
        //   icon: "el-icon-s-custom",
        //   // url: "entMemberClass",
        //   url: "course",
        //   children: [],
        // },
        {
          id: 1,
          name: "课程分配",
          icon: "el-icon-notebook-1",
          url: "classManagement",
          children: [],
        },
        {
          id: 2,
          name: "学习查询",
          icon: "el-icon-search",
          url: "studySearch",
          children: [],
        },
        {
          id: 4,
          name: "企业员工",
          icon: "el-icon-user",
          url: "memberManagement",
          children: [
            /* {
            id: 21,
            name: '我的课程',
            icon: 'el-icon-tickets',
            url: 'course',
          },
          {
            id: 22,
            name: '我的笔记',
            icon: 'el-icon-chat-dot-square',
            url: 'notebooks'
          },
          {
            id: 23,
            name: '我的教材',
            icon: 'el-icon-chat-dot-square',
            url: 'textbook'
          },
          {
            id: 24,
            name: '我的作业',
            icon: 'el-icon-bank-card',
            url: 'homework'
          },
          {
            id: 25,
            name: '换课申请',
            icon: 'el-icon-reading',
            url: 'changeCourses'
          }, */
          ],
        },
        {
          id: 3,
          name: "企业订单",
          icon: "el-icon-s-order",
          url: "orderlistManagement",
          children: [],
        },
        // {
        //   id: 6,
        //   name: "发票管理",
        //   icon: "el-icon-s-ticket",
        //   url: "invoiceManagement",
        //   children: [],
        // },
        {
          id: 5,
          name: "基本信息",
          icon: "el-icon-s-shop",
          // url: "entMemberClass",
          url: "entBasicinfo",
          children: [],
        },
        /* { // 不要
        id: 9,
        name: '升级会员',
        icon: 'el-icon-s-marketing',
        url: 'entMember',
        children:[]
      }, */
        {
          id: 12,
          name: "安全设置",
          // icon: 'el-icon-pie-chart',
          url: "setting",
          children: [
            {
              id: 7,
              name: "手机号解绑",
              icon: "el-icon-unlock",
              url: "entUnbinding",
              children: [],
            },
            {
              id: 8,
              name: "密码修改",
              icon: "el-icon-refresh-right",
              url: "entPassword",
              children: [],
            },
            /* {
              id: 11,
              name: "账号注销",
              icon: "el-icon-s-release",
              url: "entCancellation",
              children: [],
            }, */
          ],
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleMenu() {
      console.log(this.$router);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.menu-wrap {
  margin-top: 10px;
  .el-menu {
    border-right: none;
    text-align: left;
  }
  .el-menu-vertical-demo {
    width: 100% !important;
  }
  .el-submenu {
    li {
      text-align: left;
    }
  }
  .el-menu-item-group {
    text-align: left;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
}
</style>